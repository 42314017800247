<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "#ef476f",
    },
  },
  data() {
    return {
      value: "0%",
    };
  },
  mounted() {
    this.$watch("list", this.updateValue, { deep: true, immediate: true });
  },
  methods: {
    updateValue() {
      if (this.list.length) {
        const completed = this.list.reduce(
          (acc, item) => (item.isDone ? acc + 1 : acc),
          0
        );
        const result = (completed / this.list.length) * 100;
        this.value = result.toFixed(2) + "%";
      } else {
        this.value = "0%";
      }
    },
  },
};
</script>

<template>
  <div class="container-component">
    <div class="value">
      {{ value }}
    </div>
    <div class="container">
      <span class="percent"> </span>
      <div class="progress2 progress-moved">
        <div class="progress-bar2"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.value {
  font-size: 0.8rem;
  min-width: 40px;
  word-wrap: normal;
}

.container-component {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  padding: 0;
  margin: 0rem 0;
  position: relative;
}

.percent {
  position: absolute;
  font-size: 11px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  mix-blend-mode: exclusion;
}

.progress2 {
  border-radius: 30px;
  max-width: 100% !important;
  padding: 2px;
  /* border-radius: 30px; */
  background: var(--gray-200);
  .dark & {
    background: var(--gray-600);
  }
  /* box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08); */
}

.progress-bar2 {
  max-width: 100% !important;
  height: 7px;
  /* border-radius: 30px; */
  /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05)); */
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  width: v-bind(value);
  background-color: v-bind(color);
  /* animation: progressAnimation 2s; */
}

@keyframes progressAnimation {
  0% {
    width: 0%;
    background-color: #f9bcca;
  }

  100% {
    width: v-bind(value);
    background-color: v-bind(color);
  }
}
</style>
