<script>
import LegoTooltip from "@/components/Lego/LegoTooltip.vue";
import InputAutoGrow from "@/components/Lego/InputAutoGrow";
import LegoProgressBar from "@/components/Lego/LegoProgressBar.vue";
export default {
  props: {
    boardId: {
      required: true,
    },
  },
  components: {
    LegoTooltip,
    InputAutoGrow,
    LegoProgressBar,
  },
  data() {
    return {
      titleCopy: "",
      waiting: false,
      isSkeleton: true,
    };
  },
  computed: {
    board() {
      return this.$store.state.lego.board;
    },
    columns() {
      return this.board.columns;
    },
    porcentajeDelTablero() {
      if (Array.isArray(this.columns)) {
        this.isSkeleton = false;
        return this.columns.flatMap((column) => column.cards);
      } else {
        return [];
      }
    },

    title() {
      return this.board.title;
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
    isTemplate() {
      return this.board.isTemplate;
    },
    roleLevel() {
      return this.$store.state.lego.currencyRole.level;
    },
  },
  mounted() {
    this.$watch(
      () => this.title,
      () => {
        const headerInput = document.querySelector(".header-input");
        headerInput.value = this.title;
        this.parentDataSet({ target: headerInput });
      }
    );
  },
  methods: {
    async deleteBoard() {
      await this.$store.dispatch("lego/deleteBoard", this.board);
      this.$router.push({ name: "TusTableros" });
    },
    async cloneBoard() {
      this.waiting = true;
      const response = await this.$store.getters.fetchPost({
        path: `lego/CloneBoardDeepById/${this.board.id}/${this.userLoggedIn.id}`,
      });
      // const result = await response.json()
      this.waiting = false;
      if (response.ok) {
        this.$router.push({ name: "TusTableros" });
      }
    },
    async createPlantilla() {
      this.waiting = true;
      const response = await this.$store.getters.fetchPost({
        path: `lego/CreatePlantillaDeepById/${this.board.id}/${this.titleCopy}/${this.userLoggedIn.id}`,
      });
      this.waiting = false;
      if (response.ok) {
        this.$router.push({ name: "TusTableros" });
      }
    },
    async getDeepInfo() {
      const response = await this.$store.getters.fetchGet({
        path: `lego/BoardDeepById/${this.board.id}`,
      });
      const result = await response.json();
      console.log(result);
    },
    parentDataSet(ev) {
      ev.target.parentNode.dataset.value = ev.target.value;
    },
    focusOnHeaderTitle() {
      const headerInput = document.querySelector(".header-input");
      headerInput.focus();
    },
    updateTitleBoard(ev) {
      const card = {
        title: ev.target.value,
        id: this.boardId,
      };
      this.$store.dispatch("lego/setBoardTitle", card);
    },
    async clonePlantilla() {
      this.waiting = true;
      const response = await this.$store.getters.fetchPost({
        path: `lego/CreatePlantillaFromABoard/${this.board.id}/${this.userLoggedIn.id}`,
      });
      this.waiting = false;
      // try - catch
      if (response.ok) {
        this.$router.push({ name: "TusTableros" });
      }
    },
  },
};
</script>

<template>
  <header class="header">
    <div class="header-top">
      <div class="header-item">
        <!-- back btn -->
        <div
          v-b-tooltip.hover
          title="Regresar"
          class="arrow-nav lego"
          @click="$router.back()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 1024 1024"
          >
            <path
              d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0"
            />
          </svg>
        </div>
        <InputAutoGrow
          v-show="title"
          v-model="title"
          @blur="updateTitleBoard"
        />
        <b-skeleton v-show="!title" width="250px"></b-skeleton>
      </div>
      <div class="header-item">
        <slot> </slot>
        <LegoTooltip position="bottom-end">
          <span @click="focusOnHeaderTitle" v-if="roleLevel < 3"
            >Editar nombre del tablero</span
          >
          <RouterLink
            :to="{ name: 'TableroColaboradores' }"
            v-if="roleLevel < 3"
          >
            <span> Colaboradores </span>
          </RouterLink>

          <LegoTooltip
            position="left-start"
            class="cancel-default-styles"
            v-if="!isTemplate"
          >
            <template #button>
              <span>Copiar tablero</span>
            </template>
            <form @submit.prevent="cloneBoard">
              <header class="mb-2">
                <p>Copiar tablero</p>
              </header>
              <div class="body-tp">
                <p>
                  El nuevo tablero se llamará "{{ `${title} (Copia)` }}".
                  <br />
                  Al copiarlo, perderás los colaboradores y las fechas se
                  restablecerán.
                </p>
                <div class="mt-2">
                  <vs-button :loading="waiting" class="m-0">Copiar</vs-button>
                </div>
              </div>
            </form>
          </LegoTooltip>

          <LegoTooltip
            position="left-start"
            class="cancel-default-styles"
            v-if="isTemplate"
          >
            <template #button>
              <span>Copiar Plantilla</span>
            </template>
            <form @submit.prevent="clonePlantilla">
              <header class="mb-2">
                <p>Copiar Plantilla</p>
              </header>
              <div class="body-tp">
                <p>
                  La nueva plantilla se llamará "{{ `${title} (Plantilla)` }}".
                  <br />
                  Al copiarlo, perderás los colaboradores y las fechas se
                  restablecerán.
                </p>
                <div class="mt-2">
                  <vs-button :loading="waiting" class="m-0">Copiar</vs-button>
                </div>
              </div>
            </form>
          </LegoTooltip>

          <LegoTooltip
            position="left-start"
            class="cancel-default-styles"
            v-if="!isTemplate"
          >
            <template #button>
              <span>Crear plantilla</span>
            </template>
            <form @submit.prevent="clonePlantilla">
              <header class="mb-2">
                <span>Crear plantilla</span>
              </header>
              <div class="body-tp">
                <p>Se creará una plantilla a partir de este tablero.</p>
                <p>
                  La nueva plantilla se llamará "{{ `${title} (Plantilla)` }}".
                  <br />
                  Al copiarlo, perderás los colaboradores y las fechas se
                  restablecerán.
                </p>
                <div class="mt-2">
                  <vs-button :loading="waiting" class="m-0">Crear</vs-button>
                </div>
              </div>
            </form>
          </LegoTooltip>

          <span
            class="text-lego-danger"
            @click="deleteBoard"
            v-if="roleLevel == 1"
            >Eliminar tablero</span
          >
        </LegoTooltip>
      </div>
    </div>
    <div class="header-bottom">
      <LegoProgressBar v-show="!isSkeleton" :list="porcentajeDelTablero" />
      <b-skeleton v-show="isSkeleton" width="100%" height="10px"></b-skeleton>
    </div>
  </header>
</template>

<style scoped>
.wrapper-auto-grow {
  display: inline-grid;
  width: max-content;
}

.wrapper-auto-grow::after,
.wrapper-auto-grow .input-auto-grow {
  /* width: auto; */
  /* min-width: 1em; */
  grid-area: 1/2;
  font: inherit;
  padding: 0.5rem;
}

.wrapper-auto-grow::after {
  content: attr(data-value) "";
  visibility: hidden;
  white-space: pre-wrap;
}

.header {
  padding: 0.5rem 0;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  font-size: 1.3rem;
}

.header-bottom {
  /* background-color: green; */
  width: 100%;
  margin: 0;
  padding: 0 1rem;
}

.body-tp {
  text-align: left;
}

.header-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}
</style>
