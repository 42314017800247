<script>
import TableroHeader from "@/components/Lego/TableroHeader.vue";

export default {
  props: {
    boardId: {
      required: true,
    },
  },
  components: {
    TableroHeader,
    TableroKanban: () => import("@/components/Lego/TableroKanban.vue"),
    TableroGantt: () => import("@/components/Lego/TableroGantt.vue"),
  },
  data() {
    return {
      typesOfBoard: [
        { name: "Kanban", value: "TableroKanban" },
        { name: "Gantt", value: "TableroGantt" },
      ],
      selectedType: "Kanban",
    };
  },
  async mounted() {
    await this.$store.dispatch("lego/getAndSetBoardDeep", this.boardId);
  },
  beforeDestroy() {
    this.$store.dispatch("lego/cleanBoard");
  },
  computed: {
    typeOfBoard() {
      return this.typesOfBoard.find((type) => type.name == this.selectedType)
        .value;
    },
  },
};
</script>

<template>
  <div class="wrapper">
    <Router-view />
    <TableroHeader class="header" :boardId="boardId">
      <div class="typesBoards">
        <vs-button
          border
          v-for="typeOfBoard in typesOfBoard"
          :active="typeOfBoard.name == selectedType"
          :key="typeOfBoard.value"
          @click="selectedType = typeOfBoard.name"
        >
          <i
            :class="`${
              typeOfBoard.name == 'Kanban'
                ? 'fab fa-trello'
                : 'fas fa-chart-gantt'
            } mr-2`"
          ></i>
          {{ typeOfBoard.name }}
        </vs-button>
      </div>
    </TableroHeader>

    <component :is="typeOfBoard" />
  </div>
</template>

<style scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.typesBoards {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.header {
  height: 8%;
  border-bottom: solid 1px rgba(255, 255, 255, 0.25);
}
</style>
